export const BASE_URL = "https://user:pass@cd-pre-2679-course-url-redirection-hz.collegedekho.com";
export const LOG_BASE_URL = "https://user:pass@log-hz.collegedekho.com";
export const CMS_BASE_URL = "https://user:pass@cd-url-structure-hz.collegedekho.com";
export const CONTENT_TYPE_ID = {
  institute: 22,
  course: 513,
  instituteCoursePage: 1013,
  instituteMasterPage: 910,
  courseContentMasterPage: 960,
  institutePopularDegreePage: 990,
  scholarshipCategory: 1016,
  scholarshipSubCategory: 1020,
  scholarshipLandingPage: 1018,
  scholarship: 1019,
  alumni: 721,
  instituteCourse: 39,
  templateContentTypeID: 1029,
  sector: 1028,
  company: 20,
  level: 61,
  degree: 34,
  placementType: 1021,
  componentCourseContentMasterPage: 962,
  latestUpdate:819,
  faq:785
};
